.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(247, 6, 157);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.LizardNinjaLogo {
  position: center;
  top: 0;
  left: 0;
  height: 50vh;
  object-fit: cover;
}

.LizardNinjaLogo img {
  position: absolute;
  top: 0;
  left: 0;
  height: 40vh;
  object-fit: cover;
  /* This line helps to make sure your image covers the whole width and height */
}